// Variables
@import "variables_slalom.scss";

// Vendor stylesheets
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

// Partials
@import "grid.scss";
@import "buttons.scss";


html {
	position: relative;
	min-height: 100%;
}

body {
	font-family: 'Lato';
	padding-bottom: 150px !important;
}

#app-layout {
	background: url('/img/backgrounds/7-19-1030x533.jpeg') no-repeat center center fixed;
	background-size: cover;

	position: relative;
	min-height: 100vh;
	padding-bottom: 200px;
}

footer {
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	height: 150px;
	background: #f2f2f2;
	color: #707070;
	padding-top: 20px;
	font-size: 11px;
	line-height: 22px;


	li {
		display: inline;
		list-style: none;
	}
}

.fa-btn {
    margin-right: 6px;
}

.nav-tabs {
	margin-bottom: 15px;
}

.paymentSelftraitsLogo {
	width: 150px;
	border-bottom: 1px solid #ccc;
	padding-bottom: 3px;
	margin-bottom: 1px;
}

.paymentSelftraitsLogoText {
	font-weight: bold;
	letter-spacing: 10px;
	margin-left: 10px;
}

img {
	max-width: 100%;
}

.row-eq-height > div {
	padding-bottom: 15px;
}

.product-container {
	//background: white;
	//border: 3px solid #ddd;
	//padding: 0 0 10px 0;
	margin-bottom: 15px;
	//height: 100%;
}

.product-container-content {
	background: white;
	border: 3px solid #ddd;
	height: 100%;
}

.product-generic-image {
	width: 100%;
	max-width: 250px;
	padding-top: 20px;
}

.product-title {
	padding-top: 10px;
	padding-bottom: 10px;
	border-top: 3px solid #ddd;
	border-bottom: 3px solid #ddd;
	font-size: 1.2em;
}

.product-description-line1 {
	padding-top: 20px;
	margin-bottom: 0;
	font-size: 1.1em;
	font-weight: bold;
}

.product-description-line2 {
	padding-bottom: 15px;
	font-size: 0.8em;
}

.product-starting-at-regular {
	padding-top: 15px;
	color: #ddd;
	border-bottom: 3px solid #ddd;
}

.product-starting-at-special {
	padding-top: 15px;
	color: #E4300F;
	border-bottom: 3px solid #ddd;
}

.product-current-price {
	font-size: 4.5em;
	color: $brand-primary;
	margin-bottom: 0;
}

.product-dollar-sign {
	font-size: 0.3em;
	position: relative;
	bottom: 40px;
}

.product-text-tax {
	color: $brand-primary;
	position: relative;
	bottom: 17px;
	font-weight: bold;
}

.product-retail-price {
	border-top: 3px solid #ddd;
	color: #8C8C8C;
}

.product-form {
	padding: 30px 0 50px 0;
}

.product-option-prices {
	padding-bottom: 30px;
}

.share-icons {
	width: 80px;
	margin: 0 10px 0 10px;
}

/*
.nav-stacked.list-group > li {
	margin-top: 0;
	padding: 0;
}

.nav-stacked.list-group a {
	color: #333;
}

.nav-stacked.list-group .fa {
	margin-right: 10px;
	color: #777;
}*/


li .fa-fw {
	margin-right: 8px;
}

/* FOOTER */
.footer-navigation {
	margin: 20px 0 0 0;
	padding: 0;
	text-align: right;
}

.footer-navigation li {
	list-style: none;
	display: inline-block;
	margin: 0;
	padding: 0 5px 0 5px;
}
