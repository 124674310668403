// Bootstrap overrides
$border-radius-base: 0px;
$border-radius-small: 0px;
$border-radius-large: 0px;

// $nav-pills-active-link-hover-bg: #D55058;
// $brand-primary: #D55058;

// $padding-base-horizontal: 20px;
// $padding-base-vertical: 20px;
