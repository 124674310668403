.btn-twitter {
    @include button-variant(#fff, #1b95e0, #1b95e0);
}

.btn-facebook {
    @include button-variant(#fff, #3b5998, #3b5998);
}

.btn-instagram {
    @include button-variant(#fff, #9b6954, #9b6954);
}

.btn-linkedin {
    @include button-variant(#fff, #0077b5, #0077b5);
}